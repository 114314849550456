<template>
    <div ref="cardEl"
         class="sim-product-card"
         :class="{
             'sim-product-card--hover': isHovered,
             'sim-product-card--variation-selector': isCardTypeVariationSelector
         }"
    >
        <ProductCardSkeletonLoader v-if="loading" />

        <div v-else :class="{ '!hidden': loading }" class="relative flex h-full flex-col">
            <div class="sim-product-card__info">

                <div class="sim-product-card__info-tags">
                    <!--  IN STOCK text  -->
                    <BaseUiBadge
                        v-if="product?.canBePurchased(variation)"
                        color="emerald"
                        content-class="font-bold uppercase"
                        pill
                    >
                        {{ product.getAvailabilityLabel(variation) }}
                    </BaseUiBadge>
                </div>

                <ProductFavoriteButton
                    :product="product"
                    :variation="variation"
                />
            </div>

            <NuxtLink :to="productUrl">
                <div class="sim-product-card__image">
                    <CoreImg
                        class="size-full object-contain"
                        :src="product?.getHeroImage()"
                        :alt="product?.name"
                        :width="205"
                        :placeholder="[28*2, 28*2, 100, 4]"
                        sizes="50vw sm:50vw lg:400px xl:400px"
                        loading="lazy"
                    />
                </div>
            </NuxtLink>

            <!--  CONTENT  -->
            <NuxtLink :to="productUrl" class="sim-product-card__content">
                <div>
                    <span class="sim-product-card__title">
                        {{ product?.getName(variation) }}
                    </span>

                    <ProductPrice
                        :product="product"
                        :selected-variation="variation"
                    />
                </div>

                <div v-if="(product?.activeProductVariationsCount ?? 0) > 1 && !variation">
                    <Transition mode="out-in">
                        <div v-if="!isHovered || !isCardTypeVariationSelector" class="sim-product-card__variation-cnt sim-eyebrow">
                            <IconDrop height="16" />
                            {{ $t('product.variants', product!.activeProductVariationsCount!) }}
                        </div>

                        <div v-else class="sim-product-card__variation-options">
                            <LazyProductVariationSelector
                                v-for="property in product?.getVariationProperties() ?? []"
                                :key="property.id!"
                                class="sim-product-card__variation-property"
                                selector-class="sim-product-card__variation-attr-wrapper"
                                :property="property"
                                size="sm"
                                color="white"
                                display-only
                            />
                        </div>
                    </Transition>
                </div>

            </NuxtLink>

            <!--  ACTIONS (cart button)  -->
            <div class="sim-product-card__actions">
                <BaseUiButton
                    :to="renderAddToCartButton ? undefined : productUrl"
                    :on-click="renderAddToCartButton ? addItemToCart : undefined"
                    class="h-11 w-full"
                    content-class="!m-0"
                    color="primary"
                    size="large"
                    variant="outlined"
                    dense
                    outlined
                >
                    {{ renderAddToCartButton ? $t('product.add_to_cart') : $t('product.product_detail') }}
                    <template #trailing>
                        <IconShoppingBag v-if="renderAddToCartButton" />
                        <IconArrowRight v-else />
                    </template>
                </BaseUiButton>
            </div>
            <!--  end of ACTIONS  -->


        </div>
    </div>
</template>

<script lang="ts" setup>

const {
    product = null,
    variation = null,
    loading,
} = defineProps<{
    product?: InstanceType<typeof ProductModel> | null
    variation?: InstanceType<typeof ProductVariationModel> | null
    loading?: boolean
}>()

const renderAddToCartButton = computed<boolean>(() => !!(product?.canBePurchased(variation) && (!product?.hasVariations || variation)))
const { addToCart } = useCart()

const productUrl = computed<string>(() => getProductUrl(product, variation) ?? '')

async function addItemToCart() {
    if (!product?.id) return

    await addToCart({
        product_id: product.id,
        amount: 1,
        variation_id: variation?.id,
    })
}

// card hover handling
const cardEl = useTemplateRef<HTMLDivElement>('cardEl')
const isHovered = useElementHover(cardEl)


const isCardTypeVariationSelector = computed(() => {
    const numberOfVariationProperties = product?.getVariationProperties().length ?? 0
    return numberOfVariationProperties > 0 && numberOfVariationProperties <= 2 && !variation
})


</script>

<style lang="scss" scoped>
$card-padding: 1.25rem;
$card-color: $sim-c-card;

.sim-product-card {
    display: flex;
    flex-direction: column;
    padding: $card-padding;
    width: 20.5rem;
    height: 30rem;
    box-sizing: border-box;

    background-color: $card-color;

    border-radius: $sim-border-radius-sm;

    overflow: hidden;
    isolation: isolate;
}

.sim-product-list .sim-product-card {
    max-width: 100%;
    width: unset;
}

.sim-product-card__info {
    position: absolute;
    top: 0.25rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    z-index: 1;
}

.sim-product-card__info-tags {
    text-transform: uppercase;

    @include sim-text-extra-small;
    @include sim-font-medium;
}

.sim-product-card__in-stock {
    padding: 0.25rem 0.75rem;

    color: $sim-c-success-600;

    background-color: $sim-c-white;
    border-radius: 100vmax;
}

.sim-product-card__image {
    display: flex;
    align-items: center;
    justify-content: center;

    max-width: 18rem;
    height: 14rem;
    margin-top: 0.5rem;
    margin-inline: auto;

    transition: transform $sim-trans-time-long $sim-timing;
}

.sim-product-card--hover .sim-product-card__image {
    transform: scale(1.05);
}

.sim-product-card__content {
    position: relative;
    display: flex;
    flex-direction: column;
    flex: 1;
    margin-top: 1.5rem;

    gap: 1rem;
    z-index: 1;

    transition: transform $sim-trans-time-long $sim-timing;

    &::after {
        content: '';
        position: absolute;
        inset: 0;
        top: -50%;
        bottom: 35%;
        margin-inline: -$card-padding;
        z-index: -1;

        background: linear-gradient(180deg, rgb(255 255 255 / 0) 0%, $card-color 50%);
        transition: opacity $sim-trans-time-long $sim-timing;
        transition-delay: 100ms;
        opacity: 0;

        pointer-events: none;
    }
}

.sim-product-card--variation-selector.sim-product-card--hover .sim-product-card__content {
    transform: translateY(-7.5rem);

    &::after {
        opacity: 1;
        transition-duration: $sim-trans-time-normal;
        transition-delay: 0ms;
    }
}

.sim-product-card__title {
    display: block;

    @include sim-text-large;
    @include sim-font-bold;

    padding-bottom: 0.5rem;
}

.sim-product-card__variation-cnt {
    display: flex;
    align-items: center;
    gap: 0.5rem;

    color: $sim-c-simplo-darker;

    &.v-enter-active,
    &.v-leave-active {
      transition: opacity $sim-trans-time-short $sim-timing;
    }

    &.v-enter-active {
        transition-duration: $sim-trans-time-normal;
    }

    &.v-enter-from,
    &.v-leave-to {
      opacity: 0;
    }
}

.sim-product-card__variation-options {
    display: flex;
    flex-direction: column;
    gap: 1rem;

    &.v-enter-active,
    &.v-leave-active {
      transition: opacity $sim-trans-time-normal ease-out;
    }

    &.v-leave-active {
        transition-duration: $sim-trans-time-short;
    }

    &.v-enter-from,
    &.v-leave-to {
      opacity: 0;
    }
}

.sim-product-card__variation-property {
    @keyframes slide-up {
        from {
            opacity: 0;
            transform: translateY(1rem);
        }

        to {
            transform: translateY(0);
        }
    }

    animation: slide-up $sim-trans-time-normal $sim-timing;
    animation-fill-mode: both;
}

@for $i from 1 through 2 {
    .sim-product-card--variation-selector.sim-product-card--hover .sim-product-card__variation-property:nth-child(#{$i}) {
        animation-duration: #{200 + ($i - 1) * 150}ms;
    }
}

// Variation attributes fade out
:deep(.sim-product-card__variation-attr-wrapper) {
    position: relative;

    &::after {
        content: '';
        position: absolute;
        margin-inline: -$card-padding;
        margin-block: -0.5rem;
        inset: 0;
        left: calc(100% - #{$card-padding * 1.5});

        background: linear-gradient(90deg, rgb(255 255 255 / 0) 0%, $card-color 75%);
    }
}

.sim-product-card__variations {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.sim-product-card__actions {
    position: absolute;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 1;
}

</style>
