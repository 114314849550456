<template>
    <BaseUiButton
        variant="plain"
        color="basic--dark"
        :class="isWishlisted ? 'text-simplo' : 'text-basic-600'"
        size="small"
        square
        :aria-label="ariaLabel"
        :on-click="handleButtonClick"
        :show-loading="false"
    >
        <IconHeartFavorite
            :filled="isWishlisted"
            aria-hidden
        />
    </BaseUiButton>
</template>

<script lang="ts" setup>

const {
    product,
    variation,
} = defineProps<{
    product: InstanceType<typeof ProductModel> | null
    variation?: InstanceType<typeof ProductVariationModel> | null
}>()

const { t } = useI18n()
const { notifyInfo } = useNotifications()
const { addToWishlist, removeFromWishlist } = useProductWishlist()
const { loggedIn } = useAuth()
const modalsStore = useModalsStore()

const _isWishlisted = ref<boolean | undefined>(undefined)
const isWishlisted = computed<boolean>(() => _isWishlisted.value ?? product?.isWishlisted(variation) ?? false)
watch([() => product, () => variation], (val) => {
    // reset the internal state when the props change
    _isWishlisted.value = undefined
})

const ariaLabel = computed<string>(() => isWishlisted.value ? t('accessibility.remove_from_favorites') : t('accessibility.add_to_favorites'))

async function handleButtonClick() {
    if (!product) return
    if (!loggedIn.value) {
        notifyInfo(t('user.log_in_to_add_to_favorites'))
        modalsStore.isAuthModalOpen = true
        return
    }

    if (isWishlisted.value) {
        // Remove from wishlist
        _isWishlisted.value = false  // optimistic update
        _isWishlisted.value = !await removeFromWishlist(product, variation)
    } else {
        // Add to wishlist
        _isWishlisted.value = true  // optimistic update
        _isWishlisted.value = await addToWishlist(product, variation)
    }
}

</script>

<style lang="scss" scoped>

.sim-favorite-button {
    color: #B4B6BD;
}

.sim-favorite-button--active {
    color: $sim-c-black;
}

</style>
