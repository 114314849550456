<template>
    <div role="status" class="flex h-full flex-col overflow-hidden">
        <BaseUiSkeleton class="h-60" />
        <div class="flex flex-1 flex-col justify-between pt-4">
            <div>
                <BaseUiSkeleton class="mb-4 h-4 w-3/5" />
                <BaseUiSkeleton class="mb-4 h-4 w-2/5" />
                <BaseUiSkeleton class="mb-4 h-4 w-1/5" />
                <BaseUiSkeleton class="mb-4 h-4 w-1/5" />
            </div>
            <BaseUiSkeleton class="mb-0 h-10" />
        </div>
    </div>
</template>

<script lang="ts" setup>

</script>

<style lang="scss" scoped>

</style>
