import { ApiReactiveService, type ApiServiceUseFetchOptions } from '@composable-api/service/api.reactive-service'
import { type ApiServiceFetchOptions } from '@composable-api/service/api.service'
import type { ApiModel } from '@composable-api/api.model'
import { CustomerProductListItemModel } from '../models/customer-product-list-item.model'
import { type MaybeRefOrGetter, toValue } from 'vue'

class CustomerProductListItemsApiReactiveService<M extends ApiModel> extends ApiReactiveService<M> {
    useGet<T extends boolean>(options?: ApiServiceUseFetchOptions<M, T>) {
        return this.useFetch({
            ...options,
            method: 'GET',
        })
    }

    post(data: CustomerProductListItemsPayload, options?: ApiServiceFetchOptions<M>) {
        return this.fetch({
            ...options,
            method: 'POST',
            body: data,
        })
    }

    delete(options?: ApiServiceFetchOptions<M>) {
        return this.fetch({
            ...options,
            method: 'DELETE',
        })
    }
}

export default function useCustomerProductListItemsApiService(options: {
    customerProductListId: MaybeRefOrGetter<string | null | undefined>,
}) {
    return new CustomerProductListItemsApiReactiveService({
        endpoint: () => `/customer-product-lists/${toValue(options.customerProductListId)}/items`,
    }, CustomerProductListItemModel)
}

export interface CustomerProductListItemsPayload {
    note: string | null,
    product_id: number,
    product_variation_id?: number | null,
}
